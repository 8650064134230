const config = JSON.parse(document.getElementById("module-script")?.dataset?.json);

window.globalConfiguration = {
  apiUrl: config.apiUrl,
  environment: config.environment,
  cgvUrl: config.cgvUrl,
  maxxUrl: config.maxxUrl,
};

window.SGWTWidgetConfiguration = {
  environment: "production"
};